import { FC } from "react";
import Image from "next/image";

const LoadingScreen: FC = () => {
  return (
    <div className="bg-slate-200 flex items-center justify-center text-white h-screen w-screen absolute top-0 left-0 z-50">
      <Image
        alt="logo"
        src="/logo.png"
        width={42}
        height={42}
        className="animate-pulse"
      />
    </div>
  );
};

export default LoadingScreen;
