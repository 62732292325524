import { FC, useEffect } from "react";
import { useSessionContext } from "@/context/useSessionContext";
import { useRouter } from "next/navigation";
import LoadingScreen from "./LoadingScreen";

const AppLoader: FC = () => {
  const { isFetchingSession, session } = useSessionContext();
  const router = useRouter();

  useEffect(() => {
    const searchParams = new URLSearchParams(
      typeof window !== "undefined" ? window.location.search : ""
    );
    if (!isFetchingSession && !session) {
      router.push(`/register?${searchParams.toString()}`);
    }
  }, [isFetchingSession, router, session]);

  return <LoadingScreen />;
};

export default AppLoader;
