import { FC, ReactNode } from "react";

type Props = {
  leftElem: ReactNode;
  rightElem: ReactNode;
};

const SplitLayout: FC<Props> = ({ leftElem, rightElem }) => {
  return (
    <div className="flex md:flex-row flex-col w-full gap-3 pb-5 px-4 md:px-12 pt-14">
      <div className="w-full h-full rounded-md md:w-2/5">{leftElem}</div>
      <div className="w-full h-full md:w-3/5">{rightElem}</div>
    </div>
  );
};

export default SplitLayout;
