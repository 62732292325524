"use client";

import Conversation from "@/components/conversation/Conversation";
import ExamplesModal from "@/components/modal/ExamplesModal";
import QuestionForm from "@/components/setup/QuestionForm";
import { useCurrentChat } from "@/utils/useCurrentChat";
import { MessagesSquare } from "lucide-react";
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { useSessionContext } from "@/context/useSessionContext";
import AppLoader from "@/components/base/AppLoader/AppLoader";
import SplitLayout from "./components/SplitLayout";

export default function Home() {
  const [openExamplesModal, setOpenExamplesModal] = useState(false);
  const { profile } = useSessionContext();

  const { handleNewChat, reset, messages, handleFollowUpMessage, locked } =
    useCurrentChat();

  if (!profile) return <AppLoader />;

  return (
    <SplitLayout
      leftElem={
        <QuestionForm
          resetAll={reset}
          locked={locked}
          userId={profile.id}
          handleNewChat={handleNewChat}
        />
      }
      rightElem={
        messages.length > 0 ? (
          <Conversation
            messages={messages}
            handleFollowUpMessage={handleFollowUpMessage}
          />
        ) : (
          <div className="flex font-medium flex-col items-center justify-center md:h-full py-48 text-center cursor-default gap-7 text-neutral-5 border border-primary-10/20 rounded-md">
            <MessagesSquare strokeWidth={1.3} className="w-10 h-10" />
            <div className="text-sm font-medium w-56 md:w-80">
              Start a new chat with Stella by asking your first question.{" "}
              <button
                onClick={() => {
                  mixpanel.track("Opened question examples modal");
                  setOpenExamplesModal(true);
                }}
              >
                <span className="font-semibold underline decoration-[1.5px] underline-offset-2">
                  See examples
                </span>
                .
              </button>
            </div>

            <ExamplesModal
              open={openExamplesModal}
              onClose={() => setOpenExamplesModal(false)}
            />
          </div>
        )
      }
    />
  );
}
