"use client";

import { FormData } from "@/components/setup/QuestionForm";
import { Database } from "@/types/supabase";
import { SubjectKey, TopicKey } from "@/utils/subjects/subjects";
import * as Sentry from "@sentry/nextjs";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { toast } from "react-toastify";
import { Message, getGPTResponse } from "./helpers";
import { useSessionContext } from "@/context/useSessionContext";

export function useCurrentChat() {
  const supabase = createClientComponentClient<Database>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentChatUUID, setCurrentChatUUID] = useState("");
  const [locked, setLocked] = useState(false);
  const [chatSubjectKey, setChatSubjectKey] = useState<SubjectKey>();
  const [chatTopicKey, setChatTopicKey] = useState<TopicKey>();
  const { profile } = useSessionContext();

  const streamResponse = async ({
    response,
  }: {
    response: ReadableStream<Uint8Array>;
  }): Promise<string> => {
    const reader = response.getReader();
    const decoder = new TextDecoder();
    let done = false;

    const reply: Message = {
      isUser: false,
      messageContent: "",
      photoUUID: undefined,
    };

    setMessages((prevMessages) => [...prevMessages, reply]);

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      reply.messageContent += chunkValue;
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, prevMessages.length - 1),
        reply,
      ]);
    }
    return reply.messageContent;
  };

  const handleNewChat = async (userID: string, formData: FormData) => {
    mixpanel.track("Initiated new chat");
    const { message, photoData, photoUuid, subjectKey, topicKey } = formData;

    if (!subjectKey || !topicKey || !message) {
      toast.error("Please fill in all required fields");
      return;
    }

    setLocked(true);
    setChatTopicKey(topicKey);
    setChatSubjectKey(subjectKey);

    const newMessage: Message = {
      isUser: true,
      messageContent: message,
      photoUUID: photoUuid,
      photoData,
    };

    setMessages([newMessage]);

    const gptResponse = await getGPTResponse({
      messages: [newMessage],
      topicKey,
      subjectKey,
    });

    const stellaResponse = await streamResponse({ response: gptResponse });

    const { data: newChat, error: newChatError } = await supabase
      .from("chats")
      .insert([
        {
          user_id: userID,
          subject_key: subjectKey,
          topic_key: topicKey,
          question: message,
          photo_id: photoUuid,
          user_fullname: profile?.full_name,
        },
      ])
      .select()
      .single();

    if (!newChat || newChatError) {
      Sentry.captureException("Error when creating new chat: " + newChatError);
      return;
    }

    const { id, subject_key, topic_key } = newChat;

    mixpanel.track("Successfully created new chat", {
      chat_id: id,
      withPhoto: formData.photoUuid ? true : false,
      subject_key,
      topic_key,
    });

    setCurrentChatUUID(newChat.id);

    await supabase.from("messages").insert([
      {
        chat_id: id,
        order: 0,
        is_user: true,
        photo_id: formData.photoUuid,
        content: formData.message,
      },
      {
        chat_id: id,
        order: 1,
        is_user: false,
        content: stellaResponse,
      },
    ]);
  };

  const handleFollowUpMessage = async (content: string) => {
    const message: Message = {
      isUser: true,
      messageContent: content,
    };

    if (!chatTopicKey || !chatSubjectKey) {
      Sentry.captureException(
        `No chat topic or subject key when sending follow up message: chatTopicKey: ${chatTopicKey}, chatSubjectKey: ${chatSubjectKey}`
      );
      throw new Error("No chat topic or subject key");
    }

    const currentChatMessages = [...messages];

    setMessages((prev) => [...prev, message]);

    mixpanel.track("Asked follow up question", {
      current_chat_message_count: currentChatMessages.length,
    });

    const gptResponse = await getGPTResponse({
      messages: [...currentChatMessages, message],
      topicKey: chatTopicKey,
      subjectKey: chatSubjectKey,
    });

    const stellaResponse = await streamResponse({ response: gptResponse });

    await supabase.from("messages").insert([
      {
        chat_id: currentChatUUID,
        order: currentChatMessages.length,
        is_user: true,
        content: content,
      },
      {
        chat_id: currentChatUUID,
        order: currentChatMessages.length + 1,
        is_user: false,
        content: stellaResponse,
      },
    ]);
  };

  const reset = () => {
    setChatTopicKey(undefined);
    setChatSubjectKey(undefined);
    setLocked(false);
    setMessages([]);
    mixpanel.track("Reset chat");
  };

  return {
    handleNewChat,
    messages,
    handleFollowUpMessage,
    reset,
    locked,
  };
}
