import { Dialog } from "@headlessui/react";
import { MessagesSquare } from "lucide-react";
import mixpanel from "mixpanel-browser";
import Link from "next/link";
import { FC } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const EXAMPLES = [
  {
    id: "ab56c9f2-26ad-41e2-a912-8b259573ea5d",
    question: "[EXAMPLE] How do we solve this?",
    subject: "Maths Advanced",
    topic: "Financial Mathematics",
    previewMessage: "Help solve a problem",
  },
  {
    id: "f8646f32-544a-446e-832f-7317883460c3",
    question: "[EXAMPLE] How did we go from the 1st to the 2nd line?",
    subject: "Maths Extension 2",
    topic: "Mechanics",
    previewMessage: "Explain a step in a solution",
  },
  {
    id: "c3cf19c4-4c41-49d5-b4e9-96767e3fb401",
    question:
      "[EXAMPLE] Can you explain what the probability density function is?",
    subject: "Maths Advanced",
    topic: "Statistical analysis",
    previewMessage: "Clarify a concept",
  },
];

const ExamplesModal: FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      as="div"
      open={open}
      onClose={onClose}
      className="relative z-50 text-neutral-10"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 p-4 flex items-center justify-center">
        <Dialog.Panel className="mx-auto max-w-sm bg-white rounded-lg p-10">
          <Dialog.Title className="text-center flex flex-col">
            <span className="text-lg font-semibold text-primary-10">
              Not sure what to ask?
            </span>
            <span className="text-primary-6 font-normal text-sm">
              Check out some examples...
            </span>
          </Dialog.Title>
          <br />
          <div className="flex flex-col gap-3">
            {EXAMPLES.map((example) => (
              <Link
                onClick={() => {
                  mixpanel.track("Selected example", {
                    example_id: example.id,
                    example_preview: example.previewMessage,
                  });
                }}
                href={`/chat/${example.id}`}
                key={example.id}
                className="w-full py-3 px-5 flex items-center justify-start gap-3 text-sm border rounded-md hover:bg-neutral-1/30 shadow-sm hover:shadow-none"
              >
                <MessagesSquare
                  strokeWidth={2.4}
                  className="w-4 h-4 text-primary-7 shrink-0"
                />
                <span className="text-neutral-9">{example.previewMessage}</span>
              </Link>
            ))}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ExamplesModal;
